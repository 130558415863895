<template>
  <v-container style="min-height: 70vh">

      <v-row align="end">
        <v-col cols="4" class="text-right">
          <p class="headline" style="justify-content: end">First Name</p>
        </v-col>
        <v-col cols="8">
          <v-text-field
              v-model="firstName"
              :counter="20"
              label="First name"
              required
          ></v-text-field>
        </v-col>
      </v-row>


        <v-row align="end">
          <v-col cols="4" class="text-right">
            <p class="headline" style="justify-content: end">Last Name</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                v-model="lastName"
                :counter="20"
                label="Last name"
                required
            ></v-text-field>
          </v-col>
        </v-row>


        <v-row align="end">
          <v-col cols="4" class="text-right">
            <p class="headline" style="justify-content: end">Email Address</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
                v-model="emailAddr"
                :counter="20"
                label="Email address"
                required
            ></v-text-field>
          </v-col>
        </v-row>

    <v-row>
      <v-col cols="4" class="text-right">

      </v-col>
      <v-col cols="8">
        <v-textarea
            v-model="messageText"
            :counter="512"
            label="Enter your message here:"
            required
        ></v-textarea>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
export default {
  name: "Contact",
  data: () => ({
    firstName: "",
    lastName: "",
    emailAddr: ""
  })
}
</script>

<style scoped>

</style>