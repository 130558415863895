<template>
  <v-container>
    <v-row>
     <v-col cols="12" style="background-color: #1184CE">
      <p class="display-1 white--text vcenter">
        Kerry Rodgers
      </p>
     </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <p class="text-center blue--text">
          SUMMARY
        </p>
      </v-col>
      <v-col cols="8" class="wrap">
        <p>
          Energetic and passionate technology guru with extensive experience in multiple high- and low-level programming languages, development operations, systems engineering, infrastructure as code, team leadership and AWS cloud technologies. Effective at balancing competing priorities in fast-paced, critical work environments. Formulates mutually beneficial solutions to complex challenges. Outstanding communicator and relationship-builder known for going above and beyond to ensure the best possible outcomes in product quality and product availability. Regarded critical thinker, an innovator, and a solid performer.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <hr class="blue"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <p class="text-center blue--text">
          TECHNICAL SKILLS
        </p>
      </v-col>
      <v-col cols="8">
        <h1 class="headline">PROGRAMMING</h1>
        <br/>
        <ul>
          <li>Languages: C, C++, C#, Python, JavaScript </li>
          <li>IDEs: Visual Studio 2008-2019, Netbeans, Eclipse, CLion, PyCharm </li>
          <li>Libraries: STL, MFC, Boost, Flask, Express </li>
          <li>Frameworks: WPF, QT, NodeJS, Vue, Django</li>
          <li>Scripting: Bash, ZSH. CSH </li>
          <li>Source Control: Git, GitLab, Subversion  </li>
          <li>Specialties: Threading, Sockets, APIs, Microservices </li>
          <li>Operating Systems: Windows, Linux, Android, Arduino, Raspberry Pi</li>
          <li>Databases: MySQL, Postgres, Mongo, Dynamo </li>
        </ul>
        <br/>
        <br/>
        <h1 class="headline">DEVOPS / SYSTEMS ENGINEERING</h1>
        <br/>
        <ul>
          <li>CI/CD: GitLab, Jenkins, Team City </li>
          <li>Infrastructure as Code: Terragrunt, Terraform, CloudFormation </li>
          <li>AWS: VPC, EC2, ECS, S3, IAM, ACM, EFS, SM, SNS, SES, RDS, Cloud Front... </li>
          <li>Virtualization: Docker, VMWare  </li>
          <li>Configuration Management: Puppet, Ansible </li>
          <li>Service Desk Management: Jira, Solar Winds </li>
          <li>Incident Management: Ops Genie, Pager Duty </li>
          <li>Operating Systems: CentOS, Ubuntu, Debian, Amazon2, Windows Server </li>
          <li>APMs: Dynatrace, New Relic, Splunk </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <hr class="blue"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <p class="text-center blue--text">
          STRENGTHS
        </p>
      </v-col>
      <v-col cols="8">
        <ul>
          <li>More than 15 years' experience developing software across diverse industries, languages, and technologies. </li>
          <li>10 years' experience with AWS cloud technologies. </li>
          <li>Demonstrated expertise in leading and mentoring teammates in tackling new problems, features and technologies. </li>
          <li>A proven self-starter who picks up new concepts, APIs, frameworks, libraries and programming languages quickly. </li>
          <li>Extensive expertise in object-oriented architecture, design, and implementation. </li>
          <li>Aggressive problem solver that enjoys researching the best technology and approach to get the job done. </li>
          <li>Experience in Saas, PaaS, COTS, APIs, and enterprise level eCommerce </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <hr class="blue"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <p class="text-center blue--text">
          WORK HISTORY
        </p>
      </v-col>
      <v-col cols="8">
        <p class="subheading font-weight-bold">DEVELOPMENT OPERATIONS MANAGER, BENCHMARK ANALYTICS, CHICAGO, IL
        </p>
        <p>
          [09/20 - 08/21]
          <br/><br/>
          Manage Development and IT operations teams.  Implemented CI/CD, Infrastructure as Code, and disaster recovery procedures.  Founded service desk systems and policies.  Assist with the development of multiple Java/NodeJS microservice APIs.  Manage AWS Gov Cloud infrastructure for development, UAT, and production environments.  Recruit and mentor DEVOPS and ITOPS staff.  Build infrastructure and developer tools using C++ and Python for Linux and AWS Lambda platforms.  Manage IT Operations processes by planning, scheduling and assigning work through JIRA.
        </p>
        <p class="subheading font-weight-bold">ACHIEVEMENTS
        </p>
        <ul>
          <li>Improved web application security and hardened the network. </li>
          <li>Fixed issues with high availability systems.  </li>
          <li>Coordinated, planed and assisted in the implementation of site PCI compliance. </li>
          <li>Designed and implemented an S3 antivirus scanning utility.  </li>
          <li>Rearchitected and implemented infrastructure as code.  </li>
        </ul>
        <br/>
        <hr/>
        <br/>
        <p class="subheading font-weight-bold">PRINCIPAL SYSTEMS ENGINEER, ULTRACOMMERCE, CHICAGO, IL
        </p>
        <p>
          [03/17 - 09/20]
          <br/><br/>
          Primary incident escalation person for service desk and level 3 support staff.  Provide knowledge transfer and training to service desk staff levels 1, 2, and 3.  Implement tools and utilities in C++, Python, NodeJS, Bash and other scripting and programming languages.  Work directly with enterprise level customers to implement customized e-commerce CI/CD solutions utilizing AWS, Hybris and UltraServe's proprietary infrastructure and code deployment tools.  Work directly with development team to coordinate customer feature request implementation and bug resolution.  Work directly with enterprise ecommerce clients to implement best practices in CI/CD, rapid deployment of immutable containerized infrastructure, and ecommerce sites.  Work directly with enterprise client vendors to provide best practice solutions for seamless integration and code deployment.  Product and tool developer, AWS SME and product steering committee member.
        </p>
        <p class="subheading font-weight-bold">ACHIEVEMENTS
        </p>
        <ul>
          <li>Designed and implemented serverless enterprise backup and restore system using ECS Far Gate, Docker and Python. </li>
          <li>Implemented and co-architected a hybrid AWS + On-Premises enterprise e-commerce synchronization solution using Kubernetes and AWS technologies.   </li>
          <li>Built several reusable automation applications for Lambda and Linux platforms.  </li>
        </ul>
        <br/>
        <hr/>
        <br/>
        <p class="subheading font-weight-bold">DEVELOPMENT OPERATIONS MANAGER, OPTICS PLANET, CHICAGO, IL
        </p>
        <p>
          [07/16 - 03/17]
          <br/><br/>
          Manage Development Operations Engineers, Systems Administrators, and IT helpdesk personnel.  Performed duties of lead systems administrator and Development Operations engineer.  Manage IT Operations processes by planning, scheduling and assigning work through JIRA.  Maintain server operations and monitoring of over 100 VMWare hosted servers.  Architect the company’s disaster recovery system using Puppet 4.  Created on-call system by integrating various monitoring tools with Ops Genie.
        </p>
        <p class="subheading font-weight-bold">ACHIEVEMENTS
        </p>
        <ul>
          <li>Architected and implemented Puppet 4 configuration management system for ~120 Linux production servers  </li>
          <li>Improved site performance and reliability by implementing MySQL master/master/slave replication clustering.    </li>
          <li>Architected and implemented python-based application to drive Raspberry PI devices that display various business graphs on TV monitors throughout the corporate facilities.  </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Resume"
}
</script>

