<template>
  <v-container fluid style="min-height: 70vh">
    <v-row align="center" justify="center" style="min-height: 70vh">
      <div class="" style="">
        <v-row align="center">
          <v-col cols="4" class="grow text-right fill-height">
            <v-avatar
                size="256"
            >
              <img
                  :src="require('../assets/me.png')"
                  alt="Image missing, blame the programmer."
              >
            </v-avatar>
          </v-col>
          <v-col cols="6" class="mb-4 text-left">
            <v-row>
              <h3 class="display-1 font-weight-bold mb-3">
                Hello and welcome to my site,
              </h3>
            </v-row>
            <v-row>
              <h2 class="grey--text">let's get to know me:</h2>
            </v-row>
            <v-row>
              <div style="min-height: 50px">
                <vue-typed-js :strings=titles loop  @preStringTyped="loadMessage()" :smartBackspace="false" :backDelay="5000" :backSpeed="100">
                  <h1 class="typing"></h1>
                </vue-typed-js>
              </div>
            </v-row>
            <v-row>
              <v-progress-linear
                  :value="progressValue"
                  color="yellow darken-2"
              ></v-progress-linear>
            </v-row>
            <v-row>
              <p class="subheading font-weight-regular" style="min-height: 12px;">
                {{ message }}
              </p>
            </v-row>
          </v-col>

        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
let msgIndex = 0;
export default {
  name: 'HelloWorld',

  data: () => ({
    message: 'Loading...',
    progressValue: 0,
    titles: [
      'I am an experienced C++ developer.',
      'I am an experienced Python developer.',
      'I am an experienced DevOps lead.',
      'I am an innovator of things.',
      'I am a technology Guru.',
      'I am a team player who can coach.',
      'I am a can do kind of guy.'
    ],
    messages: [
      'I have more than 15 years of professional C++ programming experience.  I have worked on DOD flight simulation, antivirus tools, PC system utilities, and IOT applications.',
      'I have more than 10 years professional Python programming experience.  I am very skilled at creating automation tools and AWS lambda functions.  I have tons of experience in developing RESTFUL APIs and other microservices.',
      'I have more than 6 years of Development Operations engineering and leadership experience.  I am a wizard at infrastructure as code and have a vast amount of experience with Linux operating systems, CI/CT/CD, and AWS technologies.  ',
      'I have been regarded as an innovator and have made what seemed like software impossibilities possible throughout my career.  I am a patent contributor and POC author for a PC application resource analysis.',
      'I am a technology Guru.  I spend some of my free time working on Arduino projects and a soon-to-be released Arduino related bluetooth application for Android.  I also enjoy creating things with Unity and have created a couple of simple games, want to see?',
      'I work well in a team or on my own.  I can lead and I can follow.  I enjoy mentoring as much as learning.  I am a great relationship builder and fun to work with.',
      'Even if it sounds impossible I will give it 100%.  I enjoy the challenge of implementing new ideas and rarely fail to achieve mission success.'
    ]
  }),
  beforeDestroy: function () {
    msgIndex = 0;
  },
  methods: {
    loadMessage: function () {
      this.message = this.messages[msgIndex];
      this.progressValue = msgIndex === 0 ? 10 : 10 + 10 * msgIndex;
      if(++msgIndex === this.messages.length ) {
        msgIndex = 0;
      }
    }
  }
}
</script>
