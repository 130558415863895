<template>
  <v-container class="grow d-flex flex-column flex-nowrap">
    <v-row  align="center">
      <v-col cols="12">
        <v-card>
          <v-img
              rel="prefetch"
              height="250"
              :src="require('../assets/portfolio-card-1-bg.jpg')"
          >
            <v-row justify="center" align-center class="grow fill-height">
              <v-col cols="12" class="grow fill-height" justify="center">

                  <h1 class="display-4 text-center white--text tex">Avanquest</h1>

              </v-col>
            </v-row>
          </v-img>
          <v-card-text>
            <v-row>
              <p class="text-body-1">
                During my 7 year tenure with Avanquest Software I accomplished a great many things.
                I was hired as a temporary contract employee but after two weeks I had cleared out a rather large backlog of bugs and had also gotten rid
                of what had to be thousands of compiler warnings.  I was quickly asked to join the team as a full time member.  Shortly after I was asked
                if I could make a portable version of the System Suite application.  I was told this had been attempted more than once over the span of
                a couple of years without success.  I was able to accomplish this in a short amount of time for which I was awarded with a Principle position.
                <br/><br/>
                Below are a couple of my more note worthy accomplishments:
              </p>
            </v-row>
            <v-row>
              <h2>User Interface Progression</h2>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-img :src="require('../assets/system-suite-8-1.png')"></v-img>
              </v-col>
              <v-col cols="4">
                <v-img :src="require('../assets/system-suite-8-2.png')"></v-img>
              </v-col>
              <v-col cols="4">
                <p>
                  When I started working on the System Suite and Fix-It products the UI was based on a custom framework
                  that was authored in C++ and MFC.  At the time the application was looking pretty antiquated compared to other applications.
                  <br/><br/>
                  The UI framework was very complex since the application was branded as Fix-It as well as for several other partners like Dell, FSecure, and others.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-img :src="require('../assets/system-suite-10-1.png')"></v-img>
              </v-col>
              <v-col cols="4">
                <v-img :src="require('../assets/system-suite-10-2.png')"></v-img>
              </v-col>
              <v-col cols="4">
                <p>
                  One of my first major tasks with Avanquest was to polish the UI for an upcoming release.  I was tasked with
                  recreating the main application menu and dashboard.  I made the overall application look more modern and added
                  a sweet touch with the candy stripped animated progress bars.
                  <br/><br/>
                  Additionally I lead the development team in integrating my improvements across the application's tools and it's white box labels.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-img :src="require('../assets/system-suite-15-1.png')"></v-img>
              </v-col>
              <v-col cols="4">
                <v-img :src="require('../assets/system-suite-15-2.png')"></v-img>
              </v-col>
              <v-col cols="4">
                <p>
                  After the version 10 release management came to me and asked if we could recreate the application UI using WPF.
                  I had a couple concerns with this idea since the application's many system utility libraries were all authored in C++
                  and not one person on the development team had any experience with C#. Within a week I had a working proof of concept
                  where I had loaded a few of the applications tools in an WPF window.  I used interop between the C# UI components and the C++ libraries.
                  With this concept realized I spent the next six months creating a new UI framework and lead the development team in
                  recreating the entire UI prior to the next release.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <h2>PC Analyzer</h2>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-img :src="require('../assets/pc-analyzer-1.jpg')"></v-img>
              </v-col>
              <v-col cols="4">
                <v-img :src="require('../assets/pc-analyzer-2.jpg')"></v-img>
              </v-col>
              <v-col cols="4">
                <p>
                  After a successful remake of the UI using WPF I was tasked with creating the application's new flagship feature PC Analyzer.
                  PC Analyzer collects, monitors and displays resource consumption and behaviors of processes running on a PC.  The tool's UI
                  was authored in WPF and was partnered with a C++ Windows Service application that collected metrics from WMI.  The Windows service
                  would later be used to drive the vcom.com web application that provides remote access to PC Analyzer data as well as remote
                  access to devices using a reverse VNC system.  Additionally I architected and authored the APIs and other microservices as well as
                  the original vcom.com web application.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row  align="center">
      <v-col cols="12">
        <v-card>
          <v-img
              height="250"
              :src="require('../assets/portfolio-card-1-bg.jpg')"
          >
            <v-row justify="center" align-center class="grow fill-height">
              <v-col cols="12" class="grow fill-height" justify="center">

                <h1 class="display-4 text-center white--text tex">Hobby Stuff</h1>

              </v-col>
            </v-row>
          </v-img>
          <v-card-text>
            <v-row>
              <p class="text-body-1">
                In my spare time I like to work on my own projects.  I have published an Android game and plan to release another free
                app that allows you to control an Arduino over Bluetooth with different joystick layouts and lets you customize what is sent
                to the Arduino.  My latest interest has been with Unity and creating simple games.
              </p>
            </v-row>
            <v-row>
              <h2>Texas Holdem</h2>
            </v-row>
            <v-row>
              <v-col cols="6">
                <youtube video-id="91OzknGseTU"></youtube>
              </v-col>
              <v-col cols="6">
                <p>
                  A while back I started creating a multi-player online Texas Holdem game.  The game supported up to 10 players.  The
                  system used a custom server that created containerized microservice based rooms for games that users could join.  I spent
                  a few months on this before the code was purchased from me.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Portfolio"
}
</script>

<style scoped>

</style>